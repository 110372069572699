import { useWallets } from "@wallet-standard/react";
import {
  Col,
  ConfigProvider,
  Divider,
  Drawer,
  Flex,
  Grid,
  Menu,
  Modal,
  Row,
  Tabs,
  Tooltip,
  Tour,
  Typography,
} from "antd";
import gsap from "gsap";
import React, { useEffect, useRef, useState } from "react";
import { AiOutlineDisconnect } from "react-icons/ai";
import { FaAngleDown } from "react-icons/fa6";
import { PiCopyBold } from "react-icons/pi";
import { RiWallet3Fill } from "react-icons/ri";
import { RxHamburgerMenu } from "react-icons/rx";
import TailSpin from "react-loading-icons/dist/esm/components/tail-spin";
import { AddressPurpose, BitcoinNetworkType, getAddress } from "sats-connect";
import dfinity_backed from "../../assets/brands/icp_logo.png";
import ordinals_O_logo from "../../assets/brands/ordinals_O_logo.png";
import Bitcoin from "../../assets/coin_logo/ckbtc.png";
import Eth from "../../assets/coin_logo/cketh.png";
import logo from "../../assets/logo/ordinalslogo.png";
import Aptos from "../../assets/wallet-logo/aptos_logo.png";
import CustomButton from "../../component/Button";
import CardDisplay from "../../component/card";
import Loading from "../../component/loading-wrapper/secondary-loader";
import ModalDisplay from "../../component/modal";
import Notify from "../../component/notification";
import {
  setAirPoints,
  setLendHeader,
  setLoading,
} from "../../redux/slice/constant";
import {
  clearWalletState,
  setMagicEdenCredentials,
  setPetraAddress,
  setPetraKey,
  setPlugKey,
  setPlugPrincipalId,
  setUnisatCredentials,
  setXverseBtc,
  setXverseOrdinals,
  setXversePayment,
} from "../../redux/slice/wallet";
// import { AptosFaucetClient } from "@aptos-labs/aptos-faucet-client";
import {
  API_METHODS,
  APTOS_BRAND_KEY,
  IS_DEV,
  MAGICEDEN_WALLET_KEY,
  PETRA_WALLET_KEY,
  PLUG_WALLET_KEY,
  UNISAT_WALLET_KEY,
  XVERSE_WALLET_KEY,
  allWallets,
  apiUrl,
  host,
  sliceAddress,
  whitelist,
} from "../../utils/common";
import { propsContainer } from "../props-container";

const Nav = (props) => {
  const { Text } = Typography;
  const { useBreakpoint } = Grid;
  const breakPoint = useBreakpoint();
  const { wallets } = useWallets();

  const { location, navigate } = props.router;
  const { dispatch, reduxState } = props.redux;

  const walletState = reduxState.wallet;
  const constantState = reduxState.constant;

  const plugAddress = walletState.plug.principalId;
  const petraAddress = walletState.petra.address;
  const xverseAddress = walletState.xverse.ordinals.address;
  const unisatAddress = walletState.unisat.address;
  const magicEdenAddress = walletState.magicEden.ordinals.address;

  const [isConnectModal, setConnectModal] = useState(false);
  const [open, setOpen] = useState(false);
  const [screenDimensions, setScreenDimensions] = React.useState({
    width: window.screen.width,
    height: window.screen.height,
  });
  const [current, setCurrent] = useState();

  const avatar = process.env.REACT_APP_AVATAR;
  const devNet = process.env.REACT_APP_APTOS_DEVNET;
  const testNet = process.env.REACT_APP_APTOS_TESTNET;
  const SatsConnectNamespace = "sats-connect:";

  const { confirm } = Modal;
  const ref1 = useRef(null);
  const ref2 = useRef(null);
  const ref3 = useRef(null);
  const ref4 = useRef(null);
  const ref5 = useRef(null);

  const [openTour, setOpenTour] = useState(
    localStorage.getItem("isTourEnabled") ?? true
  );

  const tourSteps = [
    {
      title: (
        <Text strong style={{ color: "violet", fontSize: "20px" }}>
          Browse
        </Text>
      ),
      description:
        "In this you can veiw the approved collections and partners we have in group with us and we have a suprise page to view borrow and lend page.",
      target: () => ref1.current,
    },
    {
      title: (
        <Text strong style={{ color: "violet", fontSize: "20px" }}>
          Dashboard
        </Text>
      ),
      description:
        "In Dashboard page, we have the wallet supplies details, your asset supplies details, asset to supply details and we also have your lendings, asset to lend details.",
      target: () => ref2.current,
    },
    {
      title: (
        <Text strong style={{ color: "violet", fontSize: "20px" }}>
          Portfolio
        </Text>
      ),
      description:
        "In Portfolio page, we have the all the three wallet addresses plug wallet, unisat wallet and xverse wallet and we know what are the loan requests avaliable and we have details of our assets.",
      target: () => ref3.current,
    },
    {
      title: (
        <Text strong style={{ color: "violet", fontSize: "20px" }}>
          Connect Button
        </Text>
      ),
      description:
        "This button is used to connect the wallet. when you click the button modal opens with two tabs, in BTC tab we connect plug wallet and in ICP tab we connect unisat or xverse wallet",
      target: () => ref4.current,
    },
  ];

  const tourConfirm = () => {
    confirm({
      className: "backModel",
      title: (
        <Text style={{ color: "white", fontSize: "20px", marginTop: -30 }}>
          Tour Alert
        </Text>
      ),
      okText: "Yes",
      cancelText: "No",
      type: "error",
      okButtonProps: { htmlType: "submit" },
      content: (
        <>
          <Row>
            <Col>
              <Typography
                style={{ marginBottom: 5, color: "white", fontSize: "18px" }}
              >
                Are you sure want to cancel tour?
              </Typography>
            </Col>
          </Row>
        </>
      ),
      onOk() {
        localStorage.setItem("isTourEnabled", false);
        setOpenTour(localStorage.getItem("isTourEnabled"));
      },
    });
  };

  const getItem = (label, key, icon, children) => {
    return {
      key,
      icon,
      children,
      label,
    };
  };

  useEffect(() => {
    if (location.pathname === "/") {
      setCurrent("tmp-0");
    } else if (location.pathname === "/borrow") {
      setCurrent("tmp-1");
    } else if (location.pathname === "/lend") {
      setCurrent("tmp-2");
    }
    if (location.pathname === "/portfolio") {
      setCurrent("tmp-3");
    }
  }, [current, location.pathname]);

  const errorMessageNotify = (message) => {
    Notify("error", message);
  };

  const successMessageNotify = (message) => {
    Notify("success", message);
  };

  const collapseConnectedModal = () => {
    setConnectModal(!isConnectModal);
    setOpen(false);
  };

  function isSatsConnectCompatibleWallet(wallet) {
    return SatsConnectNamespace in wallet.features;
  }
  // console.log(devNet);
  // const callFaucet = async (amount, address) => {
  //   const faucetClient = new AptosFaucetClient({
  //     BASE: IS_DEV ? devNet : testNet,
  //   });
  //   const request = {
  //     amount,
  //     address,
  //   };
  //   const response = await faucetClient.fund({ requestBody: request });
  //   return response.txn_hashes;
  // };

  const connectWallet = async (walletName) => {
    if (walletName === XVERSE_WALLET_KEY) {
      const getAddressOptions = {
        payload: {
          purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
          message: "Address for receiving Ordinals and payments",
          network: {
            type: BitcoinNetworkType.Mainnet,
          },
        },
        onFinish: async (response) => {
          if (response.addresses) {
            const { addresses } = response;
            const ordinals = addresses.find(
              (ele) => ele.purpose === AddressPurpose.Ordinals
            );
            const payment = addresses.find(
              (ele) => ele.purpose === AddressPurpose.Payment
            );
            dispatch(setXversePayment(payment));
            dispatch(setXverseOrdinals(ordinals));

            const result = await API_METHODS.get(
              `${apiUrl.Unisat_open_api}/v1/indexer/address/${payment.address}/balance`,
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_UNISAT_BEARER}`,
                },
              }
            );

            const xverseBtc =
              result.data.data.satoshi / process.env.REACT_APP_BTC_ZERO;

            dispatch(setXverseBtc(xverseBtc));

            collapseConnectedModal();
            successMessageNotify("x-verse Wallet connected!");
          }
        },
        onCancel: () => errorMessageNotify("User rejected the request."),
      };
      try {
        await getAddress(getAddressOptions);
      } catch (error) {
        errorMessageNotify(error.message);
      }
    } else if (walletName === UNISAT_WALLET_KEY) {
      // UNISAT
      if (typeof window.unisat !== "undefined") {
        try {
          dispatch(setLoading(true));
          var accounts = await window.unisat.requestAccounts();
          let publicKey = await window.unisat.getPublicKey();
          let { confirmed: BtcBalance } = await window.unisat.getBalance();
          dispatch(setLoading(false));

          collapseConnectedModal();
          dispatch(
            setUnisatCredentials({
              address: accounts[0],
              publicKey,
              BtcBalance: BtcBalance / process.env.REACT_APP_BTC_ZERO,
            })
          );
          successMessageNotify("Unisat Wallet connected!");
        } catch (error) {
          dispatch(setLoading(false));
          errorMessageNotify(error.message);
        }
      } else {
        errorMessageNotify("No unisat wallet installed!");
      }
    } else if (walletName === PLUG_WALLET_KEY) {
      // PLUG
      if (window?.ic?.plug) {
        // Callback to print sessionData
        const onConnectionUpdate = () => {
          const { principalId } = window.ic.plug.sessionManager.sessionData;
          dispatch(setPlugPrincipalId(principalId));
          Notify();
        };

        try {
          const publicKey = await window.ic.plug.requestConnect({
            whitelist,
            host,
            onConnectionUpdate,
            timeout: 50000,
          });
          const pId = await window.ic.plug.principalId;
          dispatch(setPlugKey(publicKey));
          dispatch(setPlugPrincipalId(pId));
          collapseConnectedModal();
          successMessageNotify("Plug Wallet connected!");
        } catch (error) {
          errorMessageNotify(error.message);
        }
      } else {
        errorMessageNotify("No plug wallet installed!");
      }
    } else if (walletName === UNISAT_WALLET_KEY) {
      try {
        const provider = wallets.find(isSatsConnectCompatibleWallet);
        await getAddress({
          getProvider: async () =>
            provider.features[SatsConnectNamespace]?.provider,
          payload: {
            purposes: [AddressPurpose.Ordinals, AddressPurpose.Payment],
            message: "Address for receiving Ordinals and payments",
            network: {
              type: BitcoinNetworkType.Mainnet,
            },
          },
          onFinish: async (response) => {
            const { addresses } = response;
            const ordinals = addresses.find(
              (ele) => ele.purpose === AddressPurpose.Ordinals
            );
            const payment = addresses.find(
              (ele) => ele.purpose === AddressPurpose.Payment
            );

            const result = await API_METHODS.get(
              `${apiUrl.Unisat_open_api}/v1/indexer/address/${payment.address}/balance`,
              {
                headers: {
                  Authorization: `Bearer ${process.env.REACT_APP_UNISAT_BEARER}`,
                },
              }
            );

            const magicEdenBtc =
              result.data.data.satoshi / process.env.REACT_APP_BTC_ZERO;

            dispatch(
              setMagicEdenCredentials({
                ordinals,
                payment,
                btcBalance: magicEdenBtc,
              })
            );

            collapseConnectedModal();
            successMessageNotify("Magiceden Wallet connected!");
          },
          onCancel: () => {
            // alert("Request canceled");
          },
        });
      } catch (err) {
        console.log("magiceden error", err);
      }
    } else {
      // Petra wallet
      const getAptosWallet = () => {
        if (APTOS_BRAND_KEY in window) {
          return window.aptos;
        } else {
          window.open("https://petra.app/", `_blank`);
          return false;
        }
      };

      const wallet = getAptosWallet();
      try {
        if (wallet) {
          await wallet.connect();
          const { address, publicKey } = await wallet.account();
          dispatch(setPetraKey(publicKey));
          dispatch(setPetraAddress(address));
          collapseConnectedModal();
          successMessageNotify("Petra Wallet connected!");
        } else {
          Notify("error", "Connection failed!");
        }
      } catch (error) {
        // { code: 4001, message: "User rejected the request."}
      }
    }
  };

  const showDrawer = () => {
    setOpen(true);
  };
  const onClose = () => {
    setOpen(false);
  };

  const isDisabled = (key) => {
    const connected = walletState.active.includes(key);
    if (connected) return "card-disabled";
    const cond = (bool) => (bool ? "card-disabled" : "");
    switch (key) {
      case XVERSE_WALLET_KEY: {
        return cond(
          walletState.active.includes(UNISAT_WALLET_KEY) ||
            walletState.active.includes(MAGICEDEN_WALLET_KEY)
        );
      }
      case UNISAT_WALLET_KEY: {
        return cond(
          walletState.active.includes(XVERSE_WALLET_KEY) ||
            walletState.active.includes(MAGICEDEN_WALLET_KEY)
        );
      }
      case MAGICEDEN_WALLET_KEY: {
        return cond(
          walletState.active.includes(XVERSE_WALLET_KEY) ||
            walletState.active.includes(UNISAT_WALLET_KEY)
        );
      }
      default:
        return "";
    }
  };

  const walletCards = (wallet, index) => (
    <CardDisplay
      key={`${wallet.label}-${index + 1 * 123}`}
      className={`modalCardBg card-hover width pointer grey-bg m-top-bottom ${isDisabled(
        wallet.key
      )}`}
      hoverable={true}
      onClick={() => {
        connectWallet(wallet.key);
      }}
    >
      <Row align={"middle"}>
        <img
          src={wallet.image}
          alt={`${wallet.key}_logo`}
          style={{
            marginRight: wallet.key === "xverse" ? "20px" : "10px",
          }}
          width={wallet.key === "xverse" ? "7%" : "10%"}
        />{" "}
        <h2
          style={{ margin: "0" }}
          className="white-color font-courier font-large letter-spacing-medium"
          level={2}
        >
          {wallet.label}
        </h2>
      </Row>
    </CardDisplay>
  );

  const getScreenDimensions = (e) => {
    const width = window.innerWidth;
    const height = window.innerHeight;

    setScreenDimensions({ width, height });
  };

  useEffect(() => {
    window.addEventListener("resize", getScreenDimensions);
    return () => {
      window.removeEventListener("resize", getScreenDimensions);
    };
  });

  const onClick = (e) => {
    setCurrent(e.key);
  };

  const options = [
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/");
          setOpen(false);
        }}
      >
        Browse
      </Row>
    ),
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/lending");
          setOpen(false);
        }}
      >
        Lending
      </Row>
    ),
    getItem(
      <Row
        className="font-style "
        onClick={() => {
          navigate("/dashboard");
          setOpen(false);
        }}
      >
        Dashboard
      </Row>
    ),
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/staking");
          setOpen(false);
        }}
      >
        Staking
      </Row>
    ),
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/airdrop");
          setOpen(false);
        }}
      >
        Air Drop
      </Row>
    ),
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/portfolio");
          setOpen(false);
        }}
      >
        Portfolio
      </Row>
    ),
  ];

  const optionsXs = [
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/");
          setOpen(false);
        }}
      >
        Browse
      </Row>
    ),
    getItem(
      <Row
        className="font-style"
        onClick={() => {
          navigate("/lending");
          setOpen(false);
        }}
      >
        Lending
      </Row>
    ),
  ];

  const addressRendererWithCopy = (address) => {
    return (
      <Tooltip arrow title={"Copied"} trigger={"click"} placement="top">
        <PiCopyBold
          className="pointer"
          onClick={() => {
            navigator.clipboard.writeText(address);
          }}
          size={15}
        />
      </Tooltip>
    );
  };

  const avatarRenderer = (width) => (
    <img
      src={`${avatar}/svg?seed=${
        plugAddress
          ? plugAddress
          : xverseAddress
          ? xverseAddress
          : unisatAddress
          ? unisatAddress
          : magicEdenAddress
          ? magicEdenAddress
          : petraAddress
      }`}
      width={width}
      className="avatar"
      alt="avatar"
    />
  );
  gsap.to(".round", {
    rotation: 360,
    duration: 4,
    repeat: -1,
    repeatDelay: 10,
    ease: "none",
  });

  return (
    <>
      <Row
        justify={{
          xs: "space-between",
          lg: "space-between",
          xl: "space-between",
        }}
        align={"middle"}
      >
        <Col>
          <Row align={"middle"}>
            <Col>
              <div className="hover14 column">
                <div>
                  <figure>
                    <img
                      style={{ marginRight: "20px" }}
                      src={logo}
                      alt="logo"
                      className="pointer"
                      width="70px"
                      onClick={() => {
                        navigate("/");
                        dispatch(setLendHeader(false));
                      }}
                    />
                  </figure>
                </div>
              </div>
            </Col>
            {/* {screenDimensions.width >= 1200 && (
              <>
                <Flex gap={10}>
                  <Text
                    className={`${
                      location.pathname === "/"
                        ? "headertitle headerStyle"
                        : "font-style headerCompanyName"
                    } pointer heading-one `}
                    onClick={() => {
                      navigate("/");
                      dispatch(setLendHeader(false));
                    }}
                    ref={ref1}
                  >
                    Browse
                  </Text>
                  <Text className="font-xsmall color-grey">|</Text>
                  <Text
                    className={`${
                      location.pathname === "/lending"
                        ? "headertitle headerStyle"
                        : "font-style headerCompanyName"
                    } pointer heading-one `}
                    onClick={() => {
                      navigate("/lending");
                      dispatch(setLendHeader(false));
                    }}
                    ref={ref2}
                  >
                    Lending
                  </Text>
                  <Text className="font-xsmall color-grey">|</Text>

                  <Text
                    className={`${
                      location.pathname === "/dashboard"
                        ? "headertitle headerStyle"
                        : "font-style headerCompanyName"
                    } pointer heading-one `}
                    onClick={() => {
                      navigate("/dashboard");
                      dispatch(setLendHeader(false));
                    }}
                    ref={ref3}
                  >
                    Dashboard
                  </Text>
                  <Text className="font-xsmall color-grey">|</Text>
                  <Text
                    className={`${
                      location.pathname.includes("staking")
                        ? "headertitle headerStyle"
                        : "font-style headerCompanyName"
                    } pointer heading-one  `}
                    onClick={() => {
                      navigate("/staking");
                      dispatch(setLendHeader(false));
                    }}
                    ref={ref4}
                  >
                    Staking
                  </Text>
                  <Text className="font-xsmall color-grey">|</Text>
                  <Text
                    className={`${
                      location.pathname.includes("airdrops")
                        ? "headertitle headerStyle"
                        : "font-style headerCompanyName"
                    } pointer heading-one  `}
                    onClick={() => {
                      navigate("/airdrops");
                      dispatch(setLendHeader(false));
                    }}
                    ref={ref4}
                  >
                    Airdrops
                  </Text>
                  <Text className="font-xsmall color-grey">|</Text>
                  <Text
                    className={`${
                      location.pathname.includes("portfolio")
                        ? "headertitle headerStyle"
                        : "font-style headerCompanyName"
                    } pointer heading-one  `}
                    onClick={() => {
                      navigate("/portfolio");
                      dispatch(setLendHeader(false));
                    }}
                    ref={ref5}
                  >
                    Portfolio
                  </Text>
                </Flex>
              </>
            )} */}
          </Row>
        </Col>

        <Col>
          {screenDimensions.width >= 1200 && (
            <>
              <Flex gap={50}>
                <Text
                  className={`${
                    location.pathname === "/"
                      ? "headertitle headerStyle"
                      : "font-style headerCompanyName"
                  } pointer heading-one `}
                  onClick={() => {
                    navigate("/");
                    dispatch(setLendHeader(false));
                  }}
                  ref={ref1}
                >
                  Browse
                </Text>
                <Text className="font-xsmall color-grey">|</Text>
                <Text
                  className={`${
                    location.pathname === "/lending"
                      ? "headertitle headerStyle"
                      : "font-style headerCompanyName"
                  } pointer heading-one `}
                  onClick={() => {
                    navigate("/lending");
                    dispatch(setLendHeader(false));
                  }}
                  ref={ref2}
                >
                  Lending
                </Text>
                <Text className="font-xsmall color-grey">|</Text>

                <Text
                  className={`${
                    location.pathname === "/dashboard"
                      ? "headertitle headerStyle"
                      : "font-style headerCompanyName"
                  } pointer heading-one `}
                  onClick={() => {
                    navigate("/dashboard");
                    dispatch(setLendHeader(false));
                  }}
                  ref={ref3}
                >
                  Dashboard
                </Text>
                <Text className="font-xsmall color-grey">|</Text>
                <Text
                  className={`${
                    location.pathname.includes("staking")
                      ? "headertitle headerStyle"
                      : "font-style headerCompanyName"
                  } pointer heading-one  `}
                  onClick={() => {
                    navigate("/staking");
                    dispatch(setLendHeader(false));
                  }}
                  ref={ref4}
                >
                  Staking
                </Text>
                <Text className="font-xsmall color-grey">|</Text>
                <Text
                  className={`${
                    location.pathname.includes("faucet")
                      ? "headertitle headerStyle"
                      : "font-style headerCompanyName"
                  } pointer heading-one  `}
                  onClick={() => {
                    navigate("/faucet");
                    dispatch(setLendHeader(false));
                  }}
                  ref={ref4}
                >
                  Faucet
                </Text>
                <Text className="font-xsmall color-grey">|</Text>
                <Text
                  className={`${
                    location.pathname.includes("portfolio")
                      ? "headertitle headerStyle"
                      : "font-style headerCompanyName"
                  } pointer heading-one  `}
                  onClick={() => {
                    navigate("/portfolio");
                    dispatch(setLendHeader(false));
                  }}
                  ref={ref5}
                >
                  Portfolio
                </Text>
              </Flex>
            </>
          )}
        </Col>

        <Col>
          <Flex gap={10} justify="end" align={"center"} ref={ref4}>
            {plugAddress ||
            xverseAddress ||
            unisatAddress ||
            magicEdenAddress ||
            petraAddress ? (
              <Col>
                <Flex
                  gap={5}
                  align="center"
                  className="pointer avatar-wrapper"
                  onClick={showDrawer}
                  justify="space-evenly"
                >
                  {avatarRenderer(45)}
                  {screenDimensions.width > 767 && (
                    <>
                      <Text className="text-color-two font-weight-600">
                        {xverseAddress ? (
                          <>{sliceAddress(xverseAddress, 5)}</>
                        ) : unisatAddress ? (
                          <>{sliceAddress(unisatAddress, 5)}</>
                        ) : plugAddress ? (
                          <>{sliceAddress(plugAddress, 5)}</>
                        ) : magicEdenAddress ? (
                          <>{sliceAddress(magicEdenAddress, 5)}</>
                        ) : (
                          <>{sliceAddress(petraAddress, 5)}</>
                        )}
                      </Text>
                      <FaAngleDown color="white" size={20} />
                    </>
                  )}
                </Flex>
              </Col>
            ) : (
              <Col>
                {!breakPoint.xs ? (
                  <Row justify={"end"}>
                    <CustomButton
                      className="click-btn gradient-bg white-color"
                      // old btn style
                      // className="button-css lend-button"
                      title={"Connect"}
                      onClick={() => {
                        if (walletState.active.length < 2) {
                          collapseConnectedModal();
                        } else {
                          successMessageNotify("Wallet already connected!");
                        }
                      }}
                    />
                  </Row>
                ) : (
                  <RxHamburgerMenu
                    color="violet"
                    size={25}
                    onClick={showDrawer}
                  />
                )}
              </Col>
            )}
          </Flex>
        </Col>
      </Row>

      <ConfigProvider
        theme={{
          token: {
            colorPrimary: "green",
          },
        }}
      >
        <Tour
          scrollIntoViewOptions={true}
          open={openTour === "false" ? false : true}
          zIndex={1}
          animated
          onClose={(location) => {
            if (location === 3) {
              localStorage.setItem("isTourEnabled", false);
              setOpenTour(localStorage.getItem("isTourEnabled"));
            } else {
              tourConfirm();
            }
          }}
          steps={tourSteps}
          indicatorsRender={(current, total) => (
            <span>
              {current + 1} / {total}
            </span>
          )}
        />
      </ConfigProvider>

      <ModalDisplay
        open={isConnectModal}
        footer={""}
        onCancel={() => collapseConnectedModal()}
        width={breakPoint.xs ? "100%" : "32%"}
      >
        <div>
          <Row justify={"start"} align={"middle"}>
            <Text
              className={`${
                breakPoint.xs ? "font-medium" : "font-large"
              } gradient-text-one biticon heading-one`}
            >
              <RiWallet3Fill
                size={breakPoint.xs ? 27 : 35}
                className="main-bg-gradient-two border-radius-5"
              />{" "}
              Connect Wallet{" "}
            </Text>
          </Row>

          <Row justify={"start"} align={"middle"}>
            <Text
              className={`${
                breakPoint.xs ? "font-medium" : "font-small"
              } text-color-two biticon mt-30`}
            >
              Choose how you want to connect. If you don't have a wallet, you
              can select a provider and create one.
            </Text>
          </Row>

          <Row className="m-top-bottom">
            <Col>
              <Tabs
                items={[
                  {
                    key: "1",
                    label: (
                      <Row align={"middle"}>
                        <img
                          src={dfinity_backed}
                          style={{ paddingRight: "10px" }}
                          alt="noimage"
                          width="25px"
                        />
                        <Text className="font-weight-600 letter-spacing-medium text-color-one font-large">
                          {" "}
                          ICP
                        </Text>
                      </Row>
                    ),
                    children: (
                      <>
                        {allWallets.map((wallet, index) => {
                          return (
                            <Row key={`index-${wallet.key}`}>
                              {wallet.key === PLUG_WALLET_KEY ? (
                                <>{walletCards(wallet, index)}</>
                              ) : null}
                            </Row>
                          );
                        })}
                      </>
                    ),
                  },
                  {
                    key: "2",
                    label: (
                      <Row align={"middle"}>
                        <img
                          src={Bitcoin}
                          alt="noimage"
                          style={{ paddingRight: "10px" }}
                          width="25px"
                        />
                        <Text className="font-weight-600 letter-spacing-medium text-color-one font-large">
                          {" "}
                          BTC
                        </Text>
                      </Row>
                    ),
                    children: (
                      <>
                        {allWallets.map((wallet, index) => {
                          return (
                            <Row key={`index-${wallet.key}`}>
                              {wallet.key !== PLUG_WALLET_KEY &&
                              wallet.key !== PETRA_WALLET_KEY ? (
                                <>{walletCards(wallet, index)}</>
                              ) : null}
                            </Row>
                          );
                        })}
                      </>
                    ),
                  },
                  {
                    key: "3",
                    label: (
                      <Row align={"middle"}>
                        <img
                          src={Aptos}
                          alt="noimage"
                          style={{ paddingRight: "10px" }}
                          width="20px"
                        />
                        <Text className="font-weight-600 letter-spacing-medium text-color-one font-large">
                          {" "}
                          APTOS
                        </Text>
                      </Row>
                    ),
                    children: (
                      <>
                        {allWallets.map((wallet, index) => {
                          return (
                            <Row key={`index-${wallet.key}`}>
                              {wallet.key === PETRA_WALLET_KEY ? (
                                <>{walletCards(wallet, index)}</>
                              ) : null}
                            </Row>
                          );
                        })}
                      </>
                    ),
                  },
                ]}
              />
            </Col>
          </Row>
        </div>
      </ModalDisplay>

      <Drawer
        closeIcon
        width={screenDimensions.width > 425 ? "320px" : "280px"}
        style={{ height: screenDimensions.width > 1199 ? "43%" : "100%" }}
        title={
          <>
            <Row justify={"space-evenly"} align={"middle"}>
              <Flex gap={10} align="center">
                {avatarRenderer(45)}
                <Text className="text-color-one">
                  {xverseAddress ? (
                    <>{sliceAddress(xverseAddress, 5)}</>
                  ) : unisatAddress ? (
                    <>{sliceAddress(unisatAddress, 5)}</>
                  ) : magicEdenAddress ? (
                    <>{sliceAddress(magicEdenAddress, 5)}</>
                  ) : plugAddress ? (
                    <>{sliceAddress(plugAddress, 5)}</>
                  ) : (
                    <>{sliceAddress(petraAddress, 5)}</>
                  )}
                </Text>
              </Flex>
            </Row>
            <Row justify={"center"}>
              <Divider />
            </Row>
          </>
        }
        placement="right"
        closable={false}
        onClose={onClose}
        open={open}
        footer={
          <>
            {screenDimensions.width > 1199 && (
              <Row
                justify={"end"}
                className="iconalignment pointer"
                onClick={() => {
                  successMessageNotify("Your are signed out!");
                  dispatch(setAirPoints(0));
                  walletState.active.forEach((wallet) => {
                    if (wallet === XVERSE_WALLET_KEY) {
                      dispatch(clearWalletState(XVERSE_WALLET_KEY));
                    } else if (wallet === UNISAT_WALLET_KEY) {
                      dispatch(clearWalletState(UNISAT_WALLET_KEY));
                    } else if (wallet === PLUG_WALLET_KEY) {
                      dispatch(clearWalletState(PLUG_WALLET_KEY));
                    } else if (wallet === MAGICEDEN_WALLET_KEY) {
                      dispatch(clearWalletState(MAGICEDEN_WALLET_KEY));
                    } else {
                      dispatch(clearWalletState(PETRA_WALLET_KEY));
                      window.aptos.disconnect();
                    }
                  });
                  onClose();
                }}
              >
                <AiOutlineDisconnect
                  color="white"
                  style={{ fill: "chocolate" }}
                  size={25}
                />
                <Text className="text-color-two font-small heading-one">
                  Disconnect
                </Text>
              </Row>
            )}
          </>
        }
      >
        {/* Drawer Renderer */}
        <>
          <Row justify={"space-between"} align={"middle"}>
            <Col>
              <Flex align="center">
                <img
                  src={Bitcoin}
                  alt="bitcoin"
                  style={{ marginRight: "10px" }}
                  width={27}
                />
                <Flex vertical>
                  <Text className="text-color-two font-medium">Payments</Text>
                  <Text className="text-color-one font-xsmall">
                    {plugAddress ? (
                      <>
                        {sliceAddress(plugAddress, 9)}{" "}
                        {addressRendererWithCopy(plugAddress)}
                      </>
                    ) : (
                      "---"
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Col>

            <Col>
              {walletState.active.includes(PLUG_WALLET_KEY) ? null : (
                <CustomButton
                  className="font-size-18 black-bg text-color-one border-none"
                  title={"Connect"}
                  onClick={() => {
                    if (walletState.active.length < 2) {
                      collapseConnectedModal();
                    } else {
                      successMessageNotify("Wallet already connected!");
                    }
                  }}
                />
              )}
            </Col>
          </Row>

          <Row justify={"space-between"} className="mt" align={"middle"}>
            <Col>
              <Flex align="center">
                <img
                  src={ordinals_O_logo}
                  alt="bitcoin"
                  style={{ marginRight: "10px", borderRadius: "50%" }}
                  width={25}
                />
                <Flex vertical>
                  <Text className="text-color-two font-medium">Ordinals</Text>
                  <Text className="text-color-one font-xsmall">
                    {xverseAddress ? (
                      <>
                        {sliceAddress(xverseAddress, 9)}{" "}
                        {addressRendererWithCopy(xverseAddress)}
                      </>
                    ) : unisatAddress ? (
                      <>
                        {sliceAddress(unisatAddress, 9)}{" "}
                        {addressRendererWithCopy(unisatAddress)}
                      </>
                    ) : magicEdenAddress ? (
                      <>
                        {sliceAddress(magicEdenAddress, 9)}{" "}
                        {addressRendererWithCopy(magicEdenAddress)}
                      </>
                    ) : petraAddress ? (
                      <>
                        {sliceAddress(petraAddress, 9)}{" "}
                        {addressRendererWithCopy(petraAddress)}
                      </>
                    ) : (
                      "---"
                    )}
                  </Text>
                </Flex>
              </Flex>
            </Col>

            <Col>
              {walletState.active.includes(XVERSE_WALLET_KEY) ||
              walletState.active.includes(UNISAT_WALLET_KEY) ||
              walletState.active.includes(MAGICEDEN_WALLET_KEY) ||
              walletState.active.includes(PETRA_WALLET_KEY) ? null : (
                <CustomButton
                  className="font-size-18 black-bg text-color-one border-none"
                  title={"Connect"}
                  onClick={() => {
                    if (walletState.active.length < 2) {
                      collapseConnectedModal();
                    } else {
                      successMessageNotify("Wallet already connected!");
                    }
                  }}
                />
              )}
            </Col>
          </Row>

          {screenDimensions.width < 1200 && (
            <>
              <Row
                style={{ marginTop: "10px" }}
                justify={{
                  xs: "center",
                  sm: "center",
                  md: "end",
                  lg: "end",
                  xl: "end",
                }}
                className="iconalignment pointer"
                onClick={() => {
                  successMessageNotify("Your are signed out!");
                  walletState.active.forEach((wallet) => {
                    if (wallet === XVERSE_WALLET_KEY) {
                      dispatch(clearWalletState(XVERSE_WALLET_KEY));
                    } else if (wallet === UNISAT_WALLET_KEY) {
                      dispatch(clearWalletState(UNISAT_WALLET_KEY));
                    } else if (wallet === PLUG_WALLET_KEY) {
                      dispatch(clearWalletState(PLUG_WALLET_KEY));
                    } else {
                      dispatch(clearWalletState(MAGICEDEN_WALLET_KEY));
                    }
                  });
                  onClose();
                }}
              >
                <AiOutlineDisconnect
                  color="white"
                  style={{ fill: "chocolate" }}
                  size={25}
                />
                <Text className="text-color-two font-small heading-one">
                  Disconnect
                </Text>
              </Row>
              <Row justify={"center"}>
                <Divider />
              </Row>
              <Menu
                onClick={onClick}
                style={{ width: screenDimensions.width > 425 ? 270 : 230 }}
                defaultOpenKeys={["sub1"]}
                selectedKeys={[current]}
                mode="inline"
                items={breakPoint.xs ? optionsXs : options}
              />
              {screenDimensions.width < 992 && (
                <Row style={{ padding: " 0px 24px", marginTop: "10px" }}>
                  <Col>
                    <Loading
                      spin={!constantState.btcvalue}
                      indicator={
                        <TailSpin
                          stroke="#6a85f1"
                          alignmentBaseline="central"
                        />
                      }
                    >
                      {constantState.btcvalue ? (
                        <Flex gap={5}>
                          <Text className="gradient-text-one font-small heading-one">
                            BTC
                          </Text>
                          <img
                            src={Bitcoin}
                            alt="noimage"
                            style={{ justifyContent: "center" }}
                            width="35dvw"
                          />{" "}
                          <Text className="gradient-text-one font-small heading-one">
                            $ {constantState.btcvalue}
                          </Text>
                        </Flex>
                      ) : (
                        ""
                      )}
                    </Loading>
                  </Col>
                </Row>
              )}

              {screenDimensions.width < 992 && (
                <Row style={{ padding: " 0px 24px", marginTop: "20px" }}>
                  <Col>
                    <Loading
                      spin={!constantState.ethvalue}
                      indicator={
                        <TailSpin
                          stroke="#6a85f1"
                          alignmentBaseline="central"
                        />
                      }
                    >
                      {constantState.ethvalue ? (
                        <Flex gap={5}>
                          <Text className="gradient-text-one font-small heading-one">
                            ETH
                          </Text>
                          <img
                            src={Eth}
                            alt="noimage"
                            style={{ justifyContent: "center" }}
                            width="35dvw"
                          />{" "}
                          <Text className="gradient-text-one font-small heading-one">
                            $ {constantState.ethvalue}
                          </Text>
                        </Flex>
                      ) : (
                        ""
                      )}
                    </Loading>
                  </Col>
                </Row>
              )}
            </>
          )}
        </>
        {/* Drawer renderer ended */}
      </Drawer>
    </>
  );
};

export default propsContainer(Nav);
